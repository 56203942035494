<script setup lang="ts">
  const links = [
    { text: 'Неврологи', url: '/vrachi/nevrolog' },
    { text: 'Остеопаты', url: '/vrachi/osteopat' },
    { text: 'Травматологи-ортопеды', url: '/vrachi/travmatolog-ortoped' },
    { text: 'Мануальные терапевты', url: '/vrachi/manualnyj-terapevt' },
    { text: 'УЗИ', url: '/vrachi/vrach-uzi' },
    { text: 'ЛФК и реабилитация', url: '/vrachi/vrach-lfk' },
    { text: 'Рефлексотерапевты', url: '/vrachi/refleksoterapevt' },
    { text: 'Физиотерапевты', url: '/vrachi/fizioterapevt' },
    { text: 'Психотерапевты', url: '/vrachi/psihoterapevt' },
    { text: 'Психологи', url: '/vrachi/psiholog' },
    { text: 'Гинекологи', url: '/vrachi/ginekolog' },
    { text: 'Урологи', url: '/vrachi/urolog' },
    { text: 'Эндокринологи', url: '/vrachi/endokrinolog' },
    { text: 'Диетологи', url: '/vrachi/dietolog' },
    { text: 'Гастроэнтерологи', url: '/vrachi/gastroenterolog' },
    { text: 'Медицинские массажисты', url: '/vrachi/massazhist' }
  ];
</script>

<template>
  <div class="popup-doctors">
    <div class="popup-doctors-container">
      <NuxtLink v-for="link in links" :key="link.text" :to="link.url" class="doctor-link">
        {{ link.text }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-doctors {
  z-index: 100;
  display: none;
  width: 478px;
  height: 385px;
  position: absolute;
  top: 100%;
  padding-top: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  .menu-item:hover > &, &:hover {
    display: flex;
  }
}
.popup-doctors-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 8px;
  width: 100%;
  padding: 8px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.12);
}
.doctor-link {
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 16px;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  &:hover {
    background-color: #F5F6F9;
  }
}
</style>

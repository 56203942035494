<script setup lang="ts">
  import SeoState from '~/state/Search/SeoState';
  const seoState = new SeoState();
  const { currentClinic } = storeToRefs(useClinicsStore());
</script>

<template>
  <div class="mobile-search">
    <IconMagnifer class="magnifer" />
    <div class="search-wrapper">
      <div class="main-search">
        {{ seoState.searchSeoString.value.length === 0 ? ' Поиск врача, услуги, заболевания': seoState.searchSeoString.value }}
      </div>
      <div v-if="currentClinic" class="clinic-metro">
        <ClinicCircleColor :color="currentClinic.node_color" />
        <span class="text-main">
          {{ currentClinic.node_address }}
        </span>
      </div>
      <div v-else class="clinic-search">
        Все клиники
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-search {
  display: flex;
  flex-grow: 1;
  max-width: calc(100% - 96px);
  align-items: center;
  height: 52px;
  padding: 8px 10px;
  gap: 9px;
  border-radius: 1rem;
  background-color: #F5F6F9;
  cursor: pointer;
  user-select: none;
}
.magnifer {
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  margin: 0;
  color: transparent;
}
.search-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.main-search {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clinic-metro {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.text-main {
  color: #262633;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.clinic-search {
  color: #878FA2;
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
</style>

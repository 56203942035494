<script setup lang="ts">
  import type { IDoctor } from '~/EastclinicVueApi';

  const { doctorsCacheMap } = storeToRefs(useDoctorsStore());

  const doctor = computed<IDoctor|undefined>(() => {
    return doctorsCacheMap.value.values().next().value;
  });
</script>

<template>
  <div v-if="doctor" class="current-doctor-container">
    <div class="current-doctor">
      <img :src="doctor.content?.[0].url" class="doctor-img">
      <div class="current-doctor-content">
        <span class="current-doctor-name">{{ doctor.fullname }}</span>
        <div class="doctor-specials">
          {{ doctor.main_specials?.slice(0, 3).join(' · ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.current-doctor-container {
  width: calc(100% - 85px);
}
.current-doctor {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  height: 100%;
  width: 100%;
}
.doctor-img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.current-doctor-content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}
.current-doctor-name {
  display: flex;
  flex-shrink: 1;
  color: #171E36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.014px;
  overflow: hidden;
  text-wrap: nowrap;
}
.doctor-specials {
  color: #171E36;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.048px;
  overflow: hidden;
  text-wrap: nowrap;
}
</style>

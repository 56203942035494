<script setup lang="ts">
  const { clinics } = storeToRefs(useClinicsStore());
  const pageInfoStore = usePageInfoStore();
</script>

<template>
  <div class="line">
    <div class="main-container footer-clinics-container">
      <!-- id !== 42 это данные от компонента "Consultation" -->
      <FooterClinic
        v-for="clinic in clinics?.filter(c => c.id !== 42)"
        :key="clinic.id"
        :circle-color="String(clinic.node_color)"
        :text-main="clinic.node_address"
        :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
        :text-address="clinic?.address ?? ''"
        :contact-url="clinic.contact_url"
        :license="pageInfoStore.licenses[clinic.id]"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.line {
  border-top: 1px solid #E4E7EF;
  @media (max-width: 1023px) {
    display: none;
  }
}
.footer-clinics-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>

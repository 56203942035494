<template>
  <a href="https://vk.com/eastclinic?utm_source=site" rel="nofollow" target="_blank" class="contacts__item">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect class="path vk back" width="44" height="44" rx="15" fill="#F2F3F6"></rect>
      <g clip-path="url(#clip0_514_13797)">
        <path class="path vk icon" fill-rule="evenodd" clip-rule="evenodd" d="M33.45 15.948C33.616 15.402 33.45 15 32.655 15H30.03C29.362 15 29.054 15.347 28.887 15.73C28.887 15.73 27.552 18.926 25.661 21.002C25.049 21.604 24.771 21.795 24.437 21.795C24.27 21.795 24.019 21.604 24.019 21.057V15.948C24.019 15.292 23.835 15 23.279 15H19.151C18.734 15 18.483 15.304 18.483 15.593C18.483 16.214 19.429 16.358 19.526 18.106V21.904C19.526 22.737 19.373 22.888 19.039 22.888C18.149 22.888 15.984 19.677 14.699 16.003C14.45 15.288 14.198 15 13.527 15H10.9C10.15 15 10 15.347 10 15.73C10 16.412 10.89 19.8 14.145 24.281C16.315 27.341 19.37 29 22.153 29C23.822 29 24.028 28.632 24.028 27.997V25.684C24.028 24.947 24.186 24.8 24.715 24.8C25.105 24.8 25.772 24.992 27.33 26.467C29.11 28.216 29.403 29 30.405 29H33.03C33.78 29 34.156 28.632 33.94 27.904C33.702 27.18 32.852 26.129 31.725 24.882C31.113 24.172 30.195 23.407 29.916 23.024C29.527 22.533 29.638 22.314 29.916 21.877C29.916 21.877 33.116 17.451 33.449 15.948H33.45Z" fill="#171E36"></path>
      </g>
      <defs>
        <clipPath id="clip0_514_13797">
          <rect width="24" height="24" fill="white" transform="translate(10 10)"></rect>
        </clipPath>
      </defs>
    </svg>
  </a>
</template>

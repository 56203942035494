<script setup lang="ts">
  const headerStore = useHeaderStore();
</script>

<template>
  <Teleport to="body">
    <div class="modal">
      <div class="line"></div>
      <div class="container">
        <div class="title">
          <span class="text">Поиск</span>
          <div class="icon-container" @click="headerStore.closeMobileSearchModal">
            <IconClose class="icon" />
          </div>
        </div>
        <SearchPanelMainSearch />
        <SearchPanelClinicSearch />
      </div>
    </div>
    <div class="overlay" @click.stop="headerStore.closeMobileSearchModal"></div>
  </Teleport>
</template>

<style scoped lang="scss">
.modal {
  z-index: 102;
  position: fixed;
  gap: 0.5rem;
  bottom: 0;
  width: 100%;
  height: 14.5rem;
  background-color: white;
  padding: 12px 20px 32px 20px;
  border-radius: 24px 24px 0px 0px;
  user-select: none;
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 52px;
}
.text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.05px;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.line {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 32px;
  height: 4px;
  border-radius: 3px;
  opacity: 0.2;
  background: #000;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>

<script setup lang="ts">
  interface Link {
    url: string
    text: string
  }

  interface Props {
    title: string
    links: Link[]
  }

  defineProps<Props>();

  const chevron = ref(null);

  const showList = ref(false);

  const displayProperty = computed(() => {
    return showList.value ? 'flex' : 'none';
  });

  const animateChevron = (position: 'up'|'down') => {
    if (position === 'up') {
      useAnimate(chevron, [{ transform: 'rotate(0deg)' }, { transform: 'rotate(180deg)' }], 400);
      return;
    }
    useAnimate(chevron, [{ transform: 'rotate(180deg)' }, { transform: 'rotate(0deg)' }], 400);
  };

  const toggleList = () => {
    showList.value = !showList.value;
    if (showList.value) {
      animateChevron('up');
    } else {
      animateChevron('down');
    }
  };
</script>

<template>
  <div class="navigation-list-container">
    <span class="navigation-title-text" @click="toggleList">
      {{ title }}
      <IconChevronDown
        ref="chevron"
        class="chevron-down"
        :style="{ transform: `rotate(${showList?'180deg':'0'})` }"
      />
    </span>
    <div class="links-container">
      <NuxtLink
        v-for="link in links"
        :key="link.text"
        :to="link.url"
        class="navigation-link"
      >
        {{ link.text }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigation-list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 18px 0px;
    border-bottom: 1px solid #E4E7EF;
  }
}
.navigation-title-text {
  display: flex;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 1023px) {
    justify-content: space-between;
    cursor: pointer;
  }
}
.chevron-down {
  font-size: 24px;
  margin: 0px;
  color: #262633;
  @media (min-width: 1023px) {
    display: none;
  }
}
.links-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 1023px) {
    gap: 24px;
    display: v-bind(displayProperty);
  }
}
.navigation-link {
  color: #262633 !important;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  &:hover {
    color: #2D7FF9 !important;
  }
}
</style>

<script setup lang="ts">
  const { clinics } = storeToRefs(useClinicsStore());
</script>

<template>
  <div class="popup-clinics-container">
    <div class="padding-container">
      <div class="popup-clinics">
        <!-- id !== 42 это данные от компонента "Consultation" -->
        <SearchPanelClinicResult
          v-for="clinic in clinics?.filter(c => c.id !== 42)"
          :key="clinic.id"
          :circle-color="String(clinic.node_color)"
          :text-main="clinic.node_address"
          :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
          :text-address="clinic?.address ?? ''"
          class="popup-clinic"
          @click="navigateTo(clinic.contact_url)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-clinics-container {
  z-index: 100;
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding-top: 16px;
  .menu-item:hover + &, &:hover {
    display: flex;
  }
}
.padding-container {
  display: flex;
  width: 100%;
  height: 290px;
  padding: 8px;
  border-radius: 24px;
  background-color: #FFF;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.12);
}
.popup-clinics {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 340px;
}
.popup-clinic {
  border-radius: 16px;
}
</style>

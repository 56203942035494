<script setup lang="ts">
  const { clinics } = storeToRefs(useClinicsStore());
  const pageInfoStore = usePageInfoStore();

  const chevron = ref(null);

  const showList = ref(false);

  const displayProperty = computed(() => {
    return showList.value ? 'flex' : 'none';
  });

  const animateChevron = (position: 'up'|'down') => {
    if (position === 'up') {
      useAnimate(chevron, [{ transform: 'rotate(0deg)' }, { transform: 'rotate(180deg)' }], 400);
      return;
    }
    useAnimate(chevron, [{ transform: 'rotate(180deg)' }, { transform: 'rotate(0deg)' }], 400);
  };

  const toggleList = () => {
    showList.value = !showList.value;
    if (showList.value) {
      animateChevron('up');
    } else {
      animateChevron('down');
    }
  };
</script>

<template>
  <div class="navigation-clinics-container">
    <span class="navigation-title-text" @click="toggleList">
      Клиники
      <IconChevronDown
        ref="chevron"
        class="chevron-down"
        :style="{ transform: `rotate(${showList?'180deg':'0'})` }"
      />
    </span>
    <div class="links-container">
      <!-- id !== 42 это данные от компонента "Consultation" -->
      <FooterClinic
        v-for="clinic in clinics?.filter(c => c.id !== 42)"
        :key="clinic.id"
        :circle-color="String(clinic.node_color)"
        :text-main="clinic.node_address"
        :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
        :text-address="clinic?.address ?? ''"
        :contact-url="clinic.contact_url"
        :license="pageInfoStore.licenses[clinic.id]"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigation-clinics-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 18px 0px;
  border-bottom: 1px solid #E4E7EF;
  @media (min-width: 1023px) {
    display: none;
  }
}
.navigation-title-text {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.chevron-down {
  font-size: 24px;
  margin: 0px;
  color: #262633;
}
.links-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  display: v-bind(displayProperty);
  div:after {
    content: "";
    width: 100%;
    height: 1px;
    margin-top: 7px;
    background-color: #E4E7EF;
  }
  div:last-child:after {
    display: none
  }
}
</style>

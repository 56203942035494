<script setup lang="ts">
  const { navigationTabs, currentActiveTab } = storeToRefs(useNavigationStore());

  watch(currentActiveTab, (newTab) => {
    if (!newTab || import.meta.client === false) {
      return;
    }
    document.getElementById(`nav-${newTab.link}`)?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest'
    });
  });
</script>

<template>
  <ClientOnly>
    <!-- Блок "Врачи" всегда есть -->
    <div v-if="navigationTabs.length > 1" class="nav-container main-container">
      <NuxtLink
        v-for="tab in navigationTabs"
        :id="`nav-${tab.link}`"
        :key="tab.link"
        :to="`#${tab.link}`"
        :class="`nav-item ${currentActiveTab?.link === tab.link ? 'active':''}`"
      >
        {{ tab.title }}
      </NuxtLink>
    </div>
  </ClientOnly>
</template>

<style scoped lang="scss">
.nav-container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 16px;
  padding-bottom: 10px;
  overflow: scroll;
  user-select: none;

  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  @media (max-width: 1023px) {
    padding-top: 0px;
  }
}
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  text-decoration: none;
  padding: 10px 12px;
  border-radius: 16px;
  cursor: pointer;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  &:hover {
    background-color: #F5F6F9;
  }
}
.active {
  background-color: #F5F6F9;
}
</style>

<template>
  <footer>
    <FooterTop />
    <div class="footer-container">
      <div class="main-container footer-content-container">
        <div style="width: 100%">
          <FooterNavigation />
          <FooterNavigationClinics />
        </div>
        <FooterOversight />
      </div>
      <FooterAwards />
      <FooterClinics />
      <FooterBottom />
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 32px;
    padding-top: 0px;
  }
}
</style>

<script setup lang="ts">
  const links = [
    { text: 'Неврологи', url: '/vrachi/nevrolog' },
    { text: 'Остеопаты', url: '/vrachi/osteopat' },
    { text: 'Травматологи-ортопеды', url: '/vrachi/travmatolog-ortoped' },
    { text: 'Мануальные терапевты', url: '/vrachi/manualnyj-terapevt' },
    { text: 'УЗИ', url: '/vrachi/vrach-uzi' },
    { text: 'ЛФК и реабилитация', url: '/vrachi/vrach-lfk' },
    { text: 'Рефлексотерапевты', url: '/vrachi/refleksoterapevt' },
    { text: 'Физиотерапевты', url: '/vrachi/fizioterapevt' },
    { text: 'Психотерапевты', url: '/vrachi/psihoterapevt' },
    { text: 'Психологи', url: '/vrachi/psiholog' },
    { text: 'Гинекологи', url: '/vrachi/ginekolog' },
    { text: 'Урологи', url: '/vrachi/urolog' },
    { text: 'Эндокринологи', url: '/vrachi/endokrinolog' },
    { text: 'Диетологи', url: '/vrachi/dietolog' },
    { text: 'Гастроэнтерологи', url: '/vrachi/gastroenterolog' },
    { text: 'Медицинские массажисты', url: '/vrachi/massazhist' }
  ];

  const chevron = ref(null);
  const showDoctors = ref(false);

  const animateChevron = (position: 'up'|'down') => {
    if (position === 'up') {
      useAnimate(chevron, [{ transform: 'rotate(0deg)' }, { transform: 'rotate(180deg)' }], 400);
      return;
    }
    useAnimate(chevron, [{ transform: 'rotate(180deg)' }, { transform: 'rotate(0deg)' }], 400);
  };

  const toggleDoctors = () => {
    showDoctors.value = !showDoctors.value;
    if (showDoctors.value) {
      animateChevron('up');
    } else {
      animateChevron('down');
    }
  };
</script>

<template>
  <div class="container" @click="toggleDoctors">
    <div class="menu-doctors-container">
      <div class="menu-doctors">
        <IconUser class="icon-user" />
        <span class="text">Врачи</span>
      </div>
      <IconChevronDown
        ref="chevron"
        class="chevron-down"
        :style="{ transform: `rotate(${showDoctors?'180deg':'0'})` }"
      />
    </div>
    <TransitionFade>
      <div v-if="showDoctors" class="doctors-container">
        <NuxtLink v-for="link in links" :key="link.text" :to="link.url" class="doctor-link">
          {{ link.text }}
        </NuxtLink>
      </div>
    </TransitionFade>
  </div>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
}
.menu-doctors-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
  &:hover {
    background-color: #F5F6F9;
  }
}
.menu-doctors {
  display: flex;
  align-items: center;
  gap: 12px;
}
.icon-user {
  font-size: 1.5rem;
  margin: 0;
}
.text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.chevron-down {
  font-size: 1.5rem;
  margin: 0;
  color: #878FA2;
}
.doctors-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.doctor-link {
  text-decoration: none;
  border-radius: 16px;
  padding: 12px 16px;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  &:hover {
    background-color: #F5F6F9;
  }
}
</style>

<script setup lang="ts">
  const chevron = ref(null);
  const { clinics, clinicsCount } = storeToRefs(useClinicsStore());
  const showClinics = ref(false);

  const animateChevron = (position: 'up'|'down') => {
    if (position === 'up') {
      useAnimate(chevron, [{ transform: 'rotate(0deg)' }, { transform: 'rotate(180deg)' }], 400);
      return;
    }
    useAnimate(chevron, [{ transform: 'rotate(180deg)' }, { transform: 'rotate(0deg)' }], 400);
  };

  const toggleClinic = () => {
    showClinics.value = !showClinics.value;
    if (showClinics.value) {
      animateChevron('up');
    } else {
      animateChevron('down');
    }
  };
</script>

<template>
  <div class="container" @click="toggleClinic">
    <div class="menu-clinics-container">
      <div class="menu-clinics">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 12.1835C3 10.1239 3 9.09415 3.46728 8.24047C3.93456 7.38679 4.78825 6.85696 6.49563 5.79731L8.29563 4.68018C10.1004 3.56006 11.0029 3 12 3C12.9971 3 13.8996 3.56006 15.7044 4.68018L17.5044 5.79731C19.2117 6.85696 20.0654 7.38679 20.5327 8.24047C21 9.09415 21 10.1239 21 12.1835V13.5525C21 17.0633 21 18.8187 19.9456 19.9093C18.8912 21 17.1941 21 13.8 21H10.2C6.80589 21 5.10883 21 4.05442 19.9093C3 18.8187 3 17.0633 3 13.5525V12.1835Z" stroke="#262633" stroke-width="2"></path>
          <path d="M9 15C9.85038 15.6303 10.8846 16 12 16C13.1154 16 14.1496 15.6303 15 15" stroke="#262633" stroke-width="2" stroke-linecap="round"></path>
        </svg>
        <div class="text-prompt">
          <span class="text">Клиники</span>
          <ClientOnly>
            <span class="clinics-prompt">{{ clinicsCount }}</span>
          </ClientOnly>
        </div>
      </div>
      <IconChevronDown
        ref="chevron"
        class="chevron-down"
        :style="{ transform: `rotate(${showClinics?'180deg':'0'})` }"
      />
    </div>
    <TransitionFade>
      <div v-if="showClinics" class="clinic-results-container">
        <!-- id !== 42 это данные от компонента "Consultation" -->
        <SearchPanelClinicResult
          v-for="clinic in clinics?.filter(c => c.id !== 42)"
          :key="clinic.id"
          :circle-color="String(clinic.node_color)"
          :text-main="clinic.node_address"
          :text-secondary="clinicGetTextSecondary(clinic?.way, clinic.way_time)"
          :text-address="clinic?.address ?? ''"
          @click="useClinicsStore().selectClinic(clinic)"
        />
        <!-- <SearchPanelConsultation /> -->
      </div>
    </TransitionFade>
  </div>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
}
.menu-clinics-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
  &:hover {
    background-color: #F5F6F9;
  }
}
.menu-clinics {
  display: flex;
  align-items: center;
  gap: 12px;
}
.text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.chevron-down {
  font-size: 1.5rem;
  margin: 0;
  color: #878FA2;
}
.clinic-results-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0 0 1.6rem 0;
  padding: 12px 0;
}
.clinics-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  padding: 2px 8px;
  /* margin-left: 8px; */
  background-color: #2D7FF9;
  color: white;
  border-radius: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.text-prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
</style>

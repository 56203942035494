<script setup lang="ts">
  const awards = [
    { title: 'Лучшая клиника', award: 'ПроДокторов', icon: 'AwardOne' },
    { title: 'Выбор пациентов', award: 'СберЗдоровье', icon: 'AwardTwo' },
    { title: 'Выбор пациентов', award: 'НаПоправку', icon: 'AwardThree' },
    { title: 'Лучший медцентр', award: '2ГИС', icon: 'AwardFour' },
    { title: 'Хорошее место', award: 'Яндекс', icon: 'AwardFive' }
  ];
</script>

<template>
  <div class="line">
    <div class="main-container awards-container">
      <FooterAward
        v-for="award in awards"
        :key="award.icon"
        v-bind="award"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.line {
  margin-top: -30px;
  border-top: 1px solid #E4E7EF;
  @media (min-width: 1023px) {
    margin: 0;
    border: none;
  }
}
.awards-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  padding-top: 30px;
  @media (min-width: 1023px) {
    justify-content: space-between;
    padding: 0px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
</style>
